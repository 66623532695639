.bodyregister {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background:url('../assets/login_wall.jpg')no-repeat;
    background-size: cover;
    background-position: center;

}

.input-box select {
    width: 100%;
    height: 45px;
    background: transparent;
    outline: none;
    border: 2px solid black;
    border-radius: 40px;
    font-size: 16px;
    color: black;
    padding: 5px 45px 5px 20px;
}

.wrapperregister {
    width: 420px;
    background: transparent;
    border: 2px solid black;
    backdrop-filter: blur(30px);
    box-shadow: 0 0 10px black;
    color: black;
    border-radius: 10px;
    padding: 30px 40px;
}

.wrapperregister h1 {
    font-size: 36px;
    text-align: center;
}

.wrapperregister .input-box {
    position: relative;
    width: 100%;
    height: 30px;
    margin: 30px 0;
}


.wrapperregister button {
    width: 100%;
    height: 45px;
    background: #fff;
    border: 2px solid black;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: black;
    font-weight: 700;
}

.wrapperregister .register-link {
    font-size: 14.5px;
    text-align: center;
    margin:20px 0 15px;

}

.wrapperregister .input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: 2px solid black;
    border-radius: 40px;
    font-size: 16px;
    color: black;
    padding: 20px 45px 20px 20px;
}


.wrapperregister .input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-10%);
    font-size: 16px;
}