* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.bodylogin {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background:url('../assets/login_wall.jpg')no-repeat;
    background-size: cover;
    background-position: center;

}

.wrapper {
    width: 420px;
    background: transparent;
    border: 2px solid black;
    backdrop-filter: blur(30px);
    box-shadow: 0 0 10px black;
    color: black;
    border-radius: 10px;
    padding: 30px 40px;
}

.wrapper h1 {
    font-size: 36px;
    text-align: center;
}

.wrapper .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: 2px solid black;
    border-radius: 40px;
    font-size: 16px;
    color: black;
    padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
    color: black;
}

.input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
}

.wrapper .remember {
    display: flex;
    justify-content: start;
    font-size: 14.5px;
    margin: -15px 0 15px;
}

.remember label input {
    accent-color: black;
    margin-right: 4px;
}

.wrapper button {
    width: 100%;
    height: 45px;
    background: #fff;
    border: 2px solid black;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: black;
    font-weight: 700;
}

.wrapper .register-link {
    font-size: 14.5px;
    text-align: center;
    margin:20px 0 15px;

}

.register-link p a {
    color: black;
    text-decoration: none;
    font-weight: 600;
}

.register-link p a:hover {
    text-decoration: underline;
}